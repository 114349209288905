import React from 'react'
import { Container } from 'semantic-ui-react'

import { ResponsiveContainer } from '../components/responsiveContainer'
import { TextCard } from '../components/textCard'


const Company = () => (
  <ResponsiveContainer current="company">
    <Container style={{ minHeight: '100vh', paddingTop: '20px', paddingBottom: '20px'}}>
      <TextCard
        data={[{
          title: "pages.company.title",
          content: "pages.company.content"
        },{
          separator: "-",
          title: "pages.company.mission.title",
          content: "pages.company.mission.content"
        },{
          separator: "-",
          title: "pages.company.history.title",
          content: "pages.company.history.content"
        }]}
      />


    </Container>
  </ResponsiveContainer>
)

export { Company }
/*
 <Header as='h3' style={{ fontSize: '2em' }}>
          Company
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
          it's really true. It took years of gene splicing and combinatory DNA research, but our
          bananas can really dance.
        </p>
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Case Studies</a>
        </Divider>
*/
