import React from 'react'
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
} from 'semantic-ui-react'
import { Link } from "react-router-dom";

import { T } from './translate'

export const Footer = () => (
  <Segment inverted vertical style={{ padding: '5em 0em' }} className="footer">
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='About' />
            <List link inverted>
              <List.Item as='a' href="/sitemap.xml">Sitemap</List.Item>
              <List.Item as='a'>Contact Us</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Services' />
            <List link inverted>
              <List.Item
                as={ Link }
                to='/products'
              >
                <T path="menu.products" />
              </List.Item>
              <List.Item
                as={ Link }
                to='/company'
              >
                <T path="menu.company" />
              </List.Item>
              <List.Item
                as={ Link }
                to='/careers'
              >
                <T path="menu.careers" />
              </List.Item>
              <List.Item
                as={ Link }
                to='/support'
              >
                <T path="menu.support" />
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4' inverted>
              <T path="footer.gdpr.title"/>
            </Header>
            <p>
              <T path="footer.gdpr.text"/>
            </p>
            <Header as='h6' inverted>
              <T path="footer.vat"/>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
)
