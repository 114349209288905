import PropTypes from 'prop-types'
import React from 'react'

import { useMediaQuery } from 'react-responsive'

import { DesktopContainer } from './desktopContainer'
import { MobileContainer } from './mobileContainer'

import { Footer } from '../footer'

export const ResponsiveContainer = React.forwardRef(({ children, current }, ref) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const SelectedContainer = isTabletOrMobile ? MobileContainer : DesktopContainer

  return (
    <SelectedContainer current={current} ref={ref} >
      {children}
      <Footer />
    </SelectedContainer>
  )
})

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}
