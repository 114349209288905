import React from 'react'
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import classnames from "classnames"

import { languages } from '../i18n'

export const LanguageSwitch = ({ dark = false }) => {
  const { i18n } = useTranslation()

  const langOptions = languages.map((v) => ({
    key: v,
    text: v.toUpperCase(),
    value: v
  }))

  return (
    <Dropdown
      fluid
      selection
      className={classnames({ dark })}
      options={langOptions}
      value={i18n.language}
      onChange={(_e, { value }) => i18n.changeLanguage(value)}
    />
  )
}