import ReactMarkdown from 'react-markdown'
import { useTranslation } from "react-i18next";


export const T = ({ path = "", md = false }) => {
  const { t } = useTranslation();
  const translation = t(path)
  return md ? (
    <ReactMarkdown>
      {translation}
    </ReactMarkdown>
  ) : t(path)
}

export { useTranslation }
