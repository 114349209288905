import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { T, useTranslation } from './translate'

export const DownloadButton = ({
  to,
  title = "download",
  children
}) => {
  const { t } = useTranslation();
  return (
    <Button
      className="download-button"
      as={Link}
      size='huge'
      target="_blank"
      to={t(to)}
    >
      <T md path={title} />
      {children}
    </Button>
  )
}
