import React, { useState, useEffect } from 'react'
import { Button, Header, Icon, Modal, Checkbox, Form  } from 'semantic-ui-react'
import axios from 'axios'

import { T, useTranslation } from './translate'

const ENDPOINT = 'https://ilcbo9my51.execute-api.eu-south-1.amazonaws.com/default/sendContactForm'

const cleanPhone = v => {
  const trimmed = v.trimStart()
  const start = trimmed.startsWith('+') ? '+' : ''
  return start + trimmed.replace(/[^0-9-()]/g, '')
}

export const ContactModal = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const [checkIsOn, setCheckIsOn] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [checkTaC, setCheckTaC] = useState(false)

  const [errors, setErrors] = React.useState([])


  const handleVerify = () => {
    let e = []
    if (name.trim() === "") {
      e.push("NO_NAME")
    }

    const hasEmail = email.trim() !== ""
    const hasPhone = phone.trim() !== ""

    const emailIsValid = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(email)
    const phoneIsValid = phone.replace(/[^0-9]/g, '').length > 8


    if (!hasEmail && !hasPhone) {
      e.push("NO_CONTACT")
    } else {
      if (hasPhone && !phoneIsValid) {
        e.push("PHONE_INVALID")
      }

      if (hasEmail && !emailIsValid) {
        e.push("EMAIL_INVALID")
      }
    }

    if (message.trim() === "") {
      e.push("NO_MESSAGE")
    }


    setErrors(e)


    console.log(e)

    if (e.length === 0) {
      const data = {
        name,
        email,
        phone,
        message
      }

      axios.post(ENDPOINT, data)
        .then(function (response) {
          // handle success
          console.log(response);
          console.log('SEND', data)
          setCheckIsOn(false)
          setMessage("")
          setCheckTaC(false)

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })



      setOpen(false)
    } else {
      setCheckIsOn(true)
    }
  }

  useEffect(() => {
    if (checkIsOn) {
      handleVerify()
    }

  }, [
    checkIsOn,
    name,
    email,
    phone,
    message,
    checkTaC
  ])


  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon>
        <Icon name='comment alternate' />
        <T path="modals.contact.title" />
      </Header>
      <Modal.Content>
        <Form>
          <Form.Field
            error={errors.includes("NO_NAME")}
          >
            <label>
              <T path="modals.contact.form.name" />
            </label>
            <input
              value={name}
              onChange={({ target: { value }}) => setName(value)}
              type="text"
              placeholder={t("modals.contact.form.namePlaceholder")}

            />
          </Form.Field>
          <Form.Group widths='equal'>
            <Form.Input
              value={email}
              onChange={({ target: { value }}) => setEmail(value)}
              fluid
              label={t("modals.contact.form.email")}
              type='email'
              placeholder={t("modals.contact.form.emailPlaceholder")}
              error={
                errors.includes("NO_CONTACT") ? {
                  content: t("modals.contact.error.noContact"),
                  pointing: 'above',
                } :
                  errors.includes("EMAIL_INVALID") ? {
                    content: t("modals.contact.error.emailInvalid"),
                    pointing: 'above',
                  } : false
              }/>
            <Form.Input
              value={phone}
              onChange={({ target: { value }}) => setPhone(cleanPhone(value))}
              fluid
              label={t("modals.contact.form.phone")}
              placeholder={t("modals.contact.form.phonePlaceholder")}
              type="tel"
              error = {errors.includes("NO_CONTACT") ? true :
                errors.includes("PHONE_INVALID") ? {
                  content: t("modals.contact.error.phoneInvalid"), //'Please enter a valid email address'
                  pointing: 'above',
                } : false
              }
            />
          </Form.Group>
          <Form.TextArea
            value={message}
            onChange={({ target: { value }}) => setMessage(value)}
            label={t("modals.contact.form.message")}
            placeholder={t("modals.contact.form.messagePlaceholder")}
            error={errors.includes("NO_MESSAGE") ? {
              content: t("modals.contact.error.noMessage"),
              pointing: 'above',
            }: null}  />
          <Form.Field
            error={checkIsOn && !checkTaC}
          >
            <Checkbox
              label={t("modals.contact.form.tos")}
              value={checkTaC}
              onChange={(_e, { checked }) => setCheckTaC(checked)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button  color='red' inverted onClick={() => setOpen(false)}>
          <Icon name='remove' />
          <T path="modals.contact.cancel" />
        </Button>
        <Button color='green' inverted onClick={() => handleVerify()}>
          <Icon name='paper plane' />
          <T path="modals.contact.sendMessage" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
