import React from 'react'

import {
  Button,
  Container,
  Header,
  Segment,
} from 'semantic-ui-react'

import { ResponsiveContainer } from '../components/responsiveContainer'
import { T } from '../components/translate'

const Support = () => (
  <ResponsiveContainer current="support">
    <Container style={{ minHeight: '100vh', paddingTop: '20px'}}>
      <Segment>
        <Header as='h3' style={{ fontSize: '2em' }}>
          <T path="pages.support.title" />
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          <T path="pages.support.text" />
        </p>
        <Button as='a' size='large'>
          <T path="pages.support.contactUs" />
        </Button>
      </Segment>
    </Container>
  </ResponsiveContainer>
)

export { Support }
