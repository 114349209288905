import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from './routes'

import './stylesheets/app.scss';
import './utils/i18n'

const router = createBrowserRouter(routes)

//import { CookiesModal } from './components/cookiesModal'

export default function App() {
  //console.log({openContact, setOpenContact});
  return (
    <>
      <RouterProvider router={router} />
      {/* <CookiesModal /> */}
    </>
  );
}
