import React, { useState, useImperativeHandle } from 'react'
import { Link } from "react-router-dom";

import {
  Button,
  Menu
} from 'semantic-ui-react'
import { LanguageSwitch } from './languageSwitch'
import { ContactModal } from './contactModal'
import { T } from './translate'

export const Navigation = React.forwardRef((props, ref) => {
  const { fixed = false, mobile = false, current = "" } = props
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    openContactForm: () => {
      setOpen(true)
    }
  }));

  return (
    <>
      <Menu.Item
        as={ Link }
        name='home'
        to='/'
        active={current === "home"}
      >
        <T path="menu.home" />
      </Menu.Item>
      <Menu.Item
        as={ Link }
        name='products'
        to='/products'
        active={current === "products"}
      >
        <T path="menu.products" />
      </Menu.Item>
      <Menu.Item
        as={ Link }
        name='company'
        to='/company'
        active={current === "company"}
      >
        <T path="menu.company" />
      </Menu.Item>
      <Menu.Item
        as={ Link }
        name='careers'
        to='/careers'
        active={current === "careers"}
      >
        <T path="menu.careers" />
      </Menu.Item>
      <Menu.Item
        as={ Link }
        name='support'
        to='/support'
        active={current === "support"}
      >
        <T path="menu.support" />
      </Menu.Item>
      <Menu.Item position='right' style={{ paddingTop: 0, paddingBottom: 0 }}>
        <LanguageSwitch
          dark={fixed}
        />
        <Button
          style={{ minWidth: 190, marginLeft: 20 }}
          onClick={() => setOpen(true)}
          inverted={!fixed}
        >
          <T path="buttons.contact" />
        </Button>
      </Menu.Item>
      <ContactModal
        open={open}
        setOpen={setOpen}
      />
    </>
  )}
)

