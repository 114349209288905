import React, { useRef } from 'react'

import {
  Button,
  Container,
  Header,
  Segment,
} from 'semantic-ui-react'

import { ResponsiveContainer } from '../components/responsiveContainer'
import { T } from '../components/translate'

const Careers = () => {
  const container = useRef(null)

  console.log('-->', container.current)
  return (
    <ResponsiveContainer
      current="careers"
      ref={container}
    >
      <Container style={{ minHeight: '100vh', paddingTop: '20px'}}>
        <Segment>
          <Header as='h3' style={{ fontSize: '2em' }}>
            <T path="pages.carreers.title" />
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            <T path="pages.carreers.sorry" />
          </p>
          <Button
            size='large'
            onClick={() => container.current?.openContactForm()}
          >
            <T path="pages.carreers.keepMeInformed" />
          </Button>
        </Segment>
      </Container>
    </ResponsiveContainer>
  )}

export { Careers }
