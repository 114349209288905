import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { T } from './translate'

export const LinkButton = ({
  to,
  title = "download",
  children
}) => (
  <Button
    className="link-button"
    as={Link}
    size='huge'
    to={to}
  >
    <T md path={title} />
    {children}
  </Button>
)
