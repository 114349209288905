/* eslint-disable react/no-multi-comp */

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import {
  Container,
  Menu,
  Segment,
} from 'semantic-ui-react'

import { ReactComponent as Logo } from '../../assets/logo.svg'

import { Navigation } from '../navigation'

export const DesktopContainer = React.forwardRef((props, ref) => {
  const { children, current } = props
  const [ fixed, setFixed ] = useState(false)

  // navbar #22364a

  return (
    <>
      <InView onChange={(inView) => setFixed(!inView)}>
        <Segment
          inverted
          className='Navbar'
          textAlign='center'
          style={{ /*minHeight: 700,*/ padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size='large'
          >
            <Container>
              <Logo className="navbar-logo" style={{ transform: fixed ? '' : `scale(1.6) translate(-10px, -3px)` }} />
              <Navigation
                fixed={fixed}
                current={current}
                ref={ref}
              />
              {/*
                <Menu.Item position='right'>
                  <Button as='a' inverted={!fixed}>
                    Log in
                  </Button>
                  <Button as='a' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>*/}
            </Container>
          </Menu>
          {/*     <HomepageHeading />*/}
        </Segment>
      </InView>

      {children}
    </>
  )

})

DesktopContainer.propTypes = {
  children: PropTypes.node,
}
