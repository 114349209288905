import React, { Fragment } from 'react'

import {
  Divider,
  Segment,
} from 'semantic-ui-react'

import { TextBlock } from './textBlock'
import { T } from './translate'

export const TextCard = ({data = {}, noframe} ) => {
  const items = Array.isArray(data) ? data : [data]

  return (

    <Segment basic={noframe} className="text-block">
      {items.map(({title, content, separator = ""}, idx) => (
        <Fragment key={idx} >
          {separator !== "" && separator !== "-" && (
            <Divider
              className='header'
              horizontal
              fitted
            >
              {separator !== "-" && <T path={separator} /> }
            </Divider>
          )}
          {separator === "-" && (<Divider  />)}
          <TextBlock
            key={idx}
            title={title}
            content={content}
          />
        </Fragment>
      ))}
    </Segment>
  )
}


