import React from 'react'
import { Header } from 'semantic-ui-react'


import { T } from './translate'


export const TextBlock = ({ title, content }) => (
  <>
    <Header as='h3' style={{ fontSize: '2em' }}>
      <T path={title} />
    </Header>
    <T md path={content} />
  </>
)
