import React from 'react'

import {
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'

import { ResponsiveContainer } from '../components/responsiveContainer'
import { TextCard } from '../components/textCard'
import { LinkButton } from '../components/linkButton'

import elvoDocsImg from '../assets/soft/elvo-docs.png'


const Home = () => (
  <ResponsiveContainer current="home">
    <Segment style={{ padding: '3em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>

        <Grid.Row>
          <TextCard
            noframe
            data={[{
              title: "pages.home.about.title",// "Empowering Small and Medium Businesses with Innovative Software Solutions",
              content: "pages.home.about.content"
            }]}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10} style={{ paddingLeft: 0 }}>
            <TextCard
              noframe

              data={[{
                title:"pages.products.elvoDocs.title",
                content: "pages.products.elvoDocs.short"
              }]}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Image rounded size='large' src={elvoDocsImg} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <LinkButton
              to='/products'
              title="pages.home.buttons.products"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "Elevating Businesses, Empowering Success"
            </Header>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "Elvo Digital's software solutions have revolutionized the way we handle documents and manage our team. Their intuitive platforms have streamlined our processes, saving us time and resources while enhancing overall efficiency. It's like having a reliable partner by our side, dedicated to our success."
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <b>Ilia Kacherzhuk</b> CEO of WhiteSound.eu
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    {/*
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Breaking The Grid, Grabs Your Attention
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Instead of focusing on content creation and hard work, we have learned how to master the
          art of doing nothing by providing massive amounts of whitespace and generic content that
          can seem massive, monolithic and worth your attention.
        </p>
        <Button as='a' size='large'>
          Read More
        </Button>

        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Case Studies</a>
        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
          Did We Tell You About Our Bananas?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
          it's really true. It took years of gene splicing and combinatory DNA research, but our
          bananas can really dance.
        </p>
        <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button>
      </Container>
    </Segment>*/}
  </ResponsiveContainer>
)

export { Home }
