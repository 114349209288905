import React from 'react'

import {
  Container,
  Header,
  Segment,
} from 'semantic-ui-react'

import { ResponsiveContainer } from '../../components/responsiveContainer'
import { TextCard } from '../../components/textCard'
import { DownloadButton } from '../../components/downloadButton'
import { T } from '../../components/translate'

const ProductsElvoDocs = () => (
  <ResponsiveContainer current="products">
    <Container style={{ minHeight: '100vh', paddingTop: '20px'}}>
      <Header as='h1' >
        <T path="pages.products.elvoDocs.title" />
      </Header>
      <Segment>
        <TextCard
          noframe
          data={[{
            title: "pages.products.elvoDocs.title",
            content: "pages.products.elvoDocs.long"
          }]}
        />
        <DownloadButton
          title="pages.products.elvoDocs.downloadWindows"
          to="pages.products.elvoDocs.downloadWindowsLink"
        />

      </Segment>
    </Container>
  </ResponsiveContainer>
)

export { ProductsElvoDocs }
