import en from './en.i18n.json'
import it from './it.i18n.json'
import fr from './fr.i18n.json'
import de from './de.i18n.json'

const resources = {
  en: {
    translation: en
  },
  it: {
    translation: it
  },
  fr: {
    translation: fr
  },
  de: {
    translation: de
  }
}

const languages = Object.keys(resources)

export { languages, resources }

export default resources
